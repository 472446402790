export  const apiKey = '745fff882d6434c78ae4843ae559ef06';

export const global = 'https://api.themoviedb.org/3/configuration?api_key=745fff882d6434c78ae4843ae559ef06';

export const baseUrl = 'https://api.themoviedb.org/3/movie/';

export const baseUrlKey = 'api_key=745fff882d6434c78ae4843ae559ef06';

export const appendToResponse = '&append_to_response=';

